<template>
  <el-form inline>
    <el-form-item label="文件名称：">
      <el-input v-model="filename" class="file-name-input" />
    </el-form-item>
    <el-form-item label="表格宽度自适应：">
      <el-switch v-model="autoWidth" />
    </el-form-item>
    <el-button v-loading="exportLoading" @click="onExportExcel">一键导出excel</el-button>
  </el-form>
</template>
<script>
export default {
  name: 'ExportForm',
  props: {
    exportData: {
      type: Array,
      required: true
    },
    fileName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      filename: '',
      bookType: 'xlsx',
      autoWidth: false,
      exportLoading: false
    }
  },
  watch: {
    fileName(val) {
      this.filename = val
    }
  },
  methods: {
    onExportExcel() {
      this.exportLoading = true
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['日期', '内容', '链接', '阅读量', '评论', '转发', '点赞']
        const filterVal = ['created_at', 'content', 'url', 'reads_count', 'comments_count', 'reposts_count', 'attitudes_count']
        const list = this.exportData
        const data = this.formatJson(filterVal, list)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.filename,
          autoWidth: this.autoWidth,
          bookType: this.bookType
        })
        this.exportLoading = false
      })
    },

    /**
         * json转二维数组
         */
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
