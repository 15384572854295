<template>
  <el-table v-bind="$attrs" stripe show-summary class="month-data-table" v-on="$listeners">
    <el-table-column type="index" label="序号" header-align="center" align="center" />
    <el-table-column prop="created_at" label="时间" header-align="center" width="150px" />
    <el-table-column prop="content" label="内容" header-align="center" />
    <el-table-column prop="url" label="链接" header-align="center" width="220px" />
    <el-table-column prop="reads_count" label="阅读量" align="center" width="100px" />
    <el-table-column prop="comments_count" label="评论" align="center" width="100px" />
    <el-table-column prop="reposts_count" label="转发" align="center" width="100px" />
    <el-table-column prop="attitudes_count" label="点赞" align="center" width="100px" />
  </el-table>
</template>
<script>
export default {
  name: 'MonthDataTable',
  inheritAttrs: false
}
</script>
<style lang="scss" scoped>
</style>
