<template>
  <div>
    <input ref="json-upload-input" class="json-upload-input" type="file" accept=".json" @change="handleClick" />
    <div class="drop" @drop="handleDrop" @dragover="handleDragover" @dragenter="handleDragover">
      拖动JSON文件到这里 或者
      <el-button :loading="loading" style="margin-left:16px;" size="small" type="primary" @click="handleUpload">浏览</el-button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UploadJson',
  props: {
        beforeUpload: Function, // eslint-disable-line
        onSuccess: Function// eslint-disable-line
  },
  data() {
    return {
      loading: false,
      fileData: {
        name: '',
        jsonData: null
      },
      jsonData: null
    }
  },
  methods: {
    /**
         * 生成数据
         */
    generateData(results) {
      this.jsonData = results
      this.$set(this.fileData, 'jsonData', results)
      this.$emit('success', this.fileData)
    },
    handleDrop(e) {
      e.stopPropagation()
      e.preventDefault()
      if (this.loading) return
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.$message.error('只支持上传一个文件！')
        return
      }
      const rawFile = files[0] // only use files[0]

      if (!this.isJson(rawFile)) {
        this.$message.error('只支持 .json格式文件！')
        return false
      }
      this.upload(rawFile)
      e.stopPropagation()
      e.preventDefault()
    },
    handleDragover(e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleUpload() {
      this.$refs['json-upload-input'].click()
    },
    handleClick(e) {
      const files = e.target.files
      const rawFile = files[0] // only use files[0]
      if (!rawFile) return
      this.upload(rawFile)
    },
    upload(rawFile) {
      // 解决不能选择同一个文件问题
      this.$refs['json-upload-input'].value = null

      if (!this.beforeUpload) {
        this.readerData(rawFile)
        return
      }
      const before = this.beforeUpload(rawFile)
      if (before) {
        this.readerData(rawFile)
      }
    },
    /**
         * 读取数据
         */
    readerData(rawFile) {
      this.loading = true
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = e => {
          const data = JSON.parse(e.target.result)
          this.generateData(data)
          resolve()
        }
        reader.onloadend = e => {
          this.loading = false
        }
        this.$set(this.fileData, 'name', rawFile.name)
        reader.readAsText(rawFile)
      })
    },
    /**
         * 文件格式验证
         */
    isJson(file) {
      return /\.(json)$/.test(file.name)
    }
  }
}
</script>

<style scoped>
.json-upload-input{
  display: none;
  z-index: -9999;
}
.drop{
  border: 2px dashed #bbb;
  min-width: 600px;
  height: 160px;
  line-height: 160px;
  margin: 0 auto;
  font-size: 24px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
}
</style>
